<template>
  <div class="dashboard">
    <h1>Dashboard</h1>
  </div>
</template>

<script>
export default {
  name: "Dashboard",
  methods: {
    // async getVisibilityTypes() {
    //   try {
    //     const res = await this.$http.get('Action/getVisibilityTypes');
    //   } catch (e) {
    //     this.errorNotification(e.response.data.message)
    //   }
    // },
  },
  created() {
    // this.getVisibilityTypes();
    this.$router.push({name: 'permissionList'});
  }
}
</script>

<style scoped>

</style>
